import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import SimpleReactValidator from 'simple-react-validator'

class NewsletterForm extends Component {
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator()
    this.state = {
      email: '',
      status: '',
    }
  }

  handleFormSubmit = e => {
    e.preventDefault();

    if (this.validator.allValid()) {
      addToMailchimp(this.state.email)
      .then(data => {
        this.setState({
          status: data.result
        })
      })
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }

  render() {
    return (
      <form className='mb-3'>
        <div className='flex flex-col md:flex-row items-center space-y-3 space-x-0 md:space-y-0 md:space-x-3'>
          <div className="w-full md:w-6/12 xl:w-5/12 2xl:w-4/12">
            <input
              className="w-full border text-2xl p-4 font-body rounded-md"
              id="email"
              type="email"
              placeholder='name@domain.com'
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
              required
            />
            {this.validator.message('email', this.state.email, 'required|email', {className: 'text-red-500 text-xs italic'})}
          </div>

          <div className="w-full md:w-4/12 xl:w-3/12 2xl:w-2/12">
            <button
              className="w-full text-green-100 bg-green-600 hover:bg-green-700 hover:text-white px-10 py-4 rounded-md text-2xl font-semibold"
              type='submit'
              onClick={e => this.handleFormSubmit(e)}
            >
              Subscribe
            </button>
          </div>
        </div>

        {this.state.status === 'success' &&
          <div className='mt-3'>
            <div className="text-green-500 font-semibold">
              Thank you for subscribing!
            </div>
          </div>
        }

        {this.state.status === 'error' &&
          <div className='mt-3'>
            <div className="text-red-500 font-semibold">
              This email has already been added.
            </div>
          </div>
        }


      </form>
    )
  }
}

export default NewsletterForm