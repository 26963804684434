import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import NewsletterForm from "../components/forms/NewsletterForm"

const NewsletterPage = () => (
  <Layout>
    <Seo title="Newsletter" />
    <div className="max-w-7xl mx-auto px-4 mt-6 mb-24">
      <div className="">
        <h1 className="text-gray-300 text-xl md:text-2xl tracking-wider font-semibold">Weekly Newsletter</h1>
      </div>

      <div className="mt-16">
        <h2 className="text-gray-200 text-5xl lg:text-7xl font-black">Join our remote crypto dev jobs mailing list</h2>
        <h3 className="mt-10 text-gray-300 text-2xl lg:text-3xl">Get a weekly email of new remote blockchain developer job openings in your inbox. Every Friday, we send out a newsletter with the recent remote crypto developer jobs posted on the site.</h3>
      </div>

      <div className="mt-16">
        <NewsletterForm />
        <p className="text-gray-300 mt-2">No spam. Unsubscribe anytime.</p>
      </div>
    </div>
  </Layout>
)

export default NewsletterPage
